import React from "react";
import { withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";
import Cookies from "js-cookie";
import Routes from "Configs/routes";
import Metrika from "Services/Metrika";
import { sendMetriks } from "Services/sendMetriks";
import withDefaultParams, { generateUrl } from "Services/withDefaultParams";
import { EVENT_CATEGORY_PAGE_VIEW } from "Constants/metriks/GoogleCategorys";
import {
	EVENT_ACTION_CLICK_FULLED_ADDRESS_FORM_STREET,
	EVENT_ACTION_CLICK_FULLED_ADDRESS_FORM_HOME,
} from "Constants/metriks/GoogleActions";
import AlertWrapper from "ComponentsUI/AlertWrapper";
import { SearchAddressB } from "ComponentsUI/SearchAddress";
import { SearchAddressOneString } from "ComponentsUI/SearchAddress";

@withRouter
@withDefaultParams
@inject("ApplicationModel")
@observer
export default class AddressSearchHomeContainer extends React.Component {
	static defaultProps = {
		route: Routes.DomaNzl.route,
		searcheModel: null,
		searchOneStringModel: null,
		onCallback: () => {},
		onInputCallback: () => {},
	};

	constructor(props) {
		super(props);
		let text = this.props.ApplicationModel.text;
		this.errorTextCommon = text.ADDRESS_ERROR;
		this.notSelectedFullAddress = text.FULL_ADDRESS_ERROR;
		this.notCorrectHouse = text.NOT_CORRECT_HOUSE;
		this.errorTextWithoutHouse = text.ADDRESS_WITHOUT_HOUSE;
		this.hintText = text.enter_street_and_house_number;
		this.dropdownSelectorVariants = [
			{
				id: 0,
				name: text.toHome,
				route: Routes.DomaNzl.route,
			},
			{
				id: 1,
				name: text.toOffice,
				route: Routes.Business.route,
			},
			{
				id: 2,
				name: text.toOutHome,
				route: Routes.OrderOuthome.route,
			},
		];
		this.state = {
			error: false,
			errorFullAddress: false,
			errorTypeConnection: false,
			errorStreet: false,
			errorHouse: false,
			errorCounter: 0,
			selectedRoute: this.props.tender
				? this.dropdownSelectorVariants[1]
				: this.props.apartment
					? this.dropdownSelectorVariants[0]
					: -1,
			selFil: null,
			hint: false,
			otherColorHint: false,
			providerColorHint: false,
			showHint: true,
		};
	}

	changeSelectRoute(item) {
		this.setState({
			...this.state,
			selectedRoute: item,
			errorTypeConnection: false,
		});

		const searcheModel = this.props.searcheModel || this.props.ApplicationModel.searcheModel;

		searcheModel.selectRoute(item);
	}

	onMountSelFil = (e) => {
		this.setState({ selFil: e });
	};

	componentWillUnmount() {
		if (!this.props.withoutReset) {
			let searcheModel = this.props.searcheModel || this.props.ApplicationModel.searcheModel;
			if (!searcheModel.getCurrentStreet().id) {
				//searcheModel.resetData();
			}
		}
	}

	componentDidMount() {
		let path = this.props.match.path;
		if (!!this.props.sendGAWhenFulled) {
			let searcheModel = this.props.ApplicationModel.searcheModel;
			let street = searcheModel.getCurrentStreet();
			if (!!street?.id) {
				Metrika.getInstance().google.sendEvent(
					EVENT_CATEGORY_PAGE_VIEW,
					EVENT_ACTION_CLICK_FULLED_ADDRESS_FORM_STREET
				);
			}
			if (!!searcheModel.searcheForm.fields.address.value) {
				Metrika.getInstance().google.sendEvent(
					EVENT_CATEGORY_PAGE_VIEW,
					EVENT_ACTION_CLICK_FULLED_ADDRESS_FORM_HOME
				);
			}
		}

		// Место где цвет подсказки должен быть серого цвета
		const pathListOtherColorHint = [
			Routes.OrderToHome.route,
			Routes.Rates.route,
			Routes.AddressDistricts.route,
			Routes.AddressStreets.route,
			Routes.AddressStreet.route,
			Routes.Business.route,
		];

		// Место, где цвет подсказки должен быть золотого цвета
		const pathListProviderColorHint = [
			Routes.ProviderDetails.route,
			Routes.ProviderRates.route,
			Routes.ProvidersReviewListOld.route,
			Routes.ProvidersActionsList.route,
		];

		// Место, где ненадо показывать подсказку
		const pathListNoShowHint = [];
		if (
			pathListOtherColorHint.includes(path) &&
			!this.props.isPopup &&
			!this.props.isRatesAddForm
		) {
			this.setState({ otherColorHint: true });
		}
		if (pathListProviderColorHint.includes(path) && !this.props.isPopup) {
			this.setState({ providerColorHint: true });
		} else {
			this.setState({ providerColorHint: false });
		}
		if (pathListNoShowHint.includes(path)) {
			this.setState({ showHint: false });
		}

		let searchModel;
		let one_string_search =
			this.props.ApplicationModel?.siteConfig?.settings?.one_string_search?.enabled;

		if (one_string_search) {
			searchModel =
				this.props.searchOneStringModel || this.props.ApplicationModel.searcheModelOneString;
		} else {
			searchModel = this.props.searcheModel || this.props.ApplicationModel.searcheModel;
		}

		searchModel.emitSearchEvent("onComponentMount", "search_component_shown");
	}

	onInputHandler(field, value) {
		let searcheModel = this.props.searcheModel || this.props.ApplicationModel.searcheModel;
		this.removeError();
		this.setState({ hint: true });

		if (field === "street") {
			searcheModel.updateValueStreet(value);
			searcheModel.emitSearchEvent("onStreetFirstInput", value);
		} else {
			if (value.length === 1 && !/^[1-9]/.test(value)) {
				this.errorText = this.notCorrectHouse;
				this.setState({
					error: true,
					errorCounter: this.state.errorCounter + 1,
				});
				return;
			}
			searcheModel.updateValueAddress(value);
			searcheModel.emitSearchEvent("onHouseFirstInput", value);
		}
		this.props.onInputCallback();
	}

	onInputHandlerOneString(value) {
		let searcheModel =
			this.props.searchOneStringModel || this.props.ApplicationModel.searcheModelOneString;
		let lastValue = value.substring(value.length - 1);
		if (/^[1-9]/.test(lastValue)) {
			sendMetriks("ENTERING_NUMBER_NEW_SEARCH");
		}
		this.removeError();
		if (!!value) {
			this.setState({ hint: true });
		} else {
			this.setState({ hint: false });
		}
		if (this.props.ApplicationModel?.siteConfig?.settings?.one_string_search?.enabled) {
			sendMetriks("");
		}

		searcheModel.updateValueFullAddress(value);
		this.props.onInputCallback();
		searcheModel.emitSearchEvent("onAddressFirstInput", value);
	}

	focusOnDropdownHouse() {
		Boolean(this.houseDropdownInput) &&
			setTimeout(() => Boolean(this.houseDropdownInput) && this.houseDropdownInput.focus(), 100);
	}

	onItemSelect(field, item) {
		let searcheModel = this.props.searcheModel || this.props.ApplicationModel.searcheModel;
		this.removeError();
		if (field === "street") {
			searcheModel.setCurrentStreet(item?.data);
			this.focusOnDropdownHouse();
			// Metrika.getInstance().google.sendEvent(
			//     EVENT_CATEGORY_CLICK_BUTTON,
			//     EVENT_ACTION_CLICK_SELECT_STREET
			// );
			sendMetriks("CLICK_SELECT_STREET");
			sendMetriks("CLICK_FIELD_HOUSE");
		} else {
			searcheModel.setCurrentAddress(item?.data);
			if (this.state.selectedRoute == -1) {
				this.state.selFil.state.focus = true;
			}
			// Metrika.getInstance().google.sendEvent(
			//     EVENT_CATEGORY_CLICK_BUTTON,
			//     EVENT_ACTION_CLICK_SELECT_HOUSE
			// );
			sendMetriks("CLICK_SELECT_HOUSE");
		}
		if (item?.data?.address_id) {
			this.props.onCallback(item.data);
		}
	}

	onFullAddressSelect(item) {
		let searchModel =
			this.props.searchOneStringModel || this.props.ApplicationModel.searcheModelOneString;
		this.removeError();

		if (item.data.address_id || item.data.house_guid || item.data.house) {
			if (this.state.selectedRoute == -1) {
				this.state.selFil.state.focus = true;
			}

			searchModel.setCurrentFullAddress(item).then((item) => {
				if (item?.data?.address_id) {
					this.props.onCallback(item.data);
				}
			});

			this.setState({ hint: false });
			sendMetriks("CLICK_SELECT_STREET_AND_HOUSE_NEW_SEARCH");
		} else {
			searchModel.setCurrentFullAddressStreetOnly(item);

			this.setState({ hint: true });
			sendMetriks("CLICK_SELECT_STREET_NEW_SEARCH");
		}
	}

	onDropdownOpenCallback(type) {
		let fixedModel = this.props.ApplicationModel.fixedPanelModel;
		fixedModel.hideFixedPanel();

		let searchModel;
		let one_string_search =
			this.props.ApplicationModel?.siteConfig?.settings?.one_string_search?.enabled;

		if (one_string_search) {
			searchModel =
				this.props.searchOneStringModel || this.props.ApplicationModel.searcheModelOneString;
		} else {
			searchModel = this.props.searcheModel || this.props.ApplicationModel.searcheModel;
		}

		if (type == "street") {
			searchModel.emitSearchEvent("onOtherSearchAction", "street_focus");
		} else if (type == "house") {
			searchModel.emitSearchEvent("onOtherSearchAction", "house_focus");
		}
	}

	onDropdownCloseCallback() {
		let fixedModel = this.props.ApplicationModel.fixedPanelModel;
		fixedModel.showFixedPanel();
	}

	onDropdownCloseCallbackOneString() {
		let fixedModel = this.props.ApplicationModel.fixedPanelModel;
		fixedModel.showFixedPanel();

		this.setState({ hint: false });
	}

	removeError() {
		this.setState({
			error: false,
			errorFullAddress: false,
			errorTypeConnection: false,
			errorHouse: false,
		});
	}

	goToRates() {
		let searcheModel = this.props.searcheModel || this.props.ApplicationModel.searcheModel;
		let link = null;
		let searchHouse = false;
		let regions = this.props.ApplicationModel.siteConfig.regions.available;
		let currentRegion = this.props.ApplicationModel.currentRegion.url;
		let newRegionUrl = this.props.location?.state?.is404
			? currentRegion.url
			: this.props.match.params.regionUrl;
		let currentAddress = searcheModel.getCurrentAddress();
		let currentStreet = searcheModel.getCurrentStreet();

		if (!currentAddress.id) {
			let addresses = searcheModel.getAddresses();
			let finded = addresses.find(
				(item) =>
					item.data.full.toLowerCase() ===
					searcheModel.searcheForm.fields.address.value.toLowerCase()
			);
			if (!!finded) {
				currentAddress = finded.data;
				searcheModel.setCurrentAddress(finded.data);
			} else {
				currentAddress.id = -1;
			}
		}

		if (currentAddress.id === -1) {
			if (this.state.selectedRoute.route === this.dropdownSelectorVariants[0].route) {
				!!this.props.openPopup && this.props.openPopup();

				sendMetriks("CLICK_POPUP_WRONG_HOUSE_LOAD");

				searcheModel.emitSearchEvent("onOtherSearchAction", "search_fail");

				return;
			}

			let region = regions.find((item) => item.id == currentStreet.region_id);
			if (region.url != newRegionUrl) {
				newRegionUrl = region.url;
				this.props.ApplicationModel.detectRegionModel.setAccept(region);
				this.props.ApplicationModel.setRegion(region);
			}

			link = generateUrl(this.state.selectedRoute.route, {
				regionUrl: newRegionUrl,
			});
			if (this.state.selectedRoute.route === this.dropdownSelectorVariants[1].route) {
				link = link + `?u_house=1`;
			}

			!!this.props.successCallback && this.props.successCallback();
			this.props.history.push(link);

			searcheModel.emitSearchEvent("onOtherSearchAction", "search_fail");

			return;
		}

		if (this.state.selectedRoute.route === this.dropdownSelectorVariants[0].route) {
			searchHouse = true;
			let commonViewModel = this.props.ApplicationModel.commonViewModel;
			if (this.props.providerId) {
				let providerModel = this.props.ApplicationModel.providerModel;
				providerModel.setProviderIdForOrder(this.props.providerId);
			}
			commonViewModel.changeShowModalOnRates(true);
			commonViewModel.changeNameModalOnRates("quiz_search");
		}

		let region = regions.find((item) => item.id == currentAddress.region_id);
		if (region.url != newRegionUrl) {
			newRegionUrl = region.url;
			this.props.ApplicationModel.detectRegionModel.setAccept(region);
			this.props.ApplicationModel.setRegion(region);
		}

		// Уникальный URL для золотых домов
		link =
			searchHouse && Boolean(currentAddress.url)
				? generateUrl(Routes.RatesInHouse.route, {
						regionUrl: newRegionUrl,
						house_url: currentAddress.url,
						house_id: currentAddress.id,
					})
				: generateUrl(this.state.selectedRoute.route, {
						regionUrl: newRegionUrl,
					});

		if (
			this.state.selectedRoute.route === this.dropdownSelectorVariants[0].route ||
			this.state.selectedRoute.route === this.dropdownSelectorVariants[1].route
		) {
			searcheModel.setHouseId(currentAddress.id);
		}

		this.props.history.push(link);
		searcheModel.emitSearchEvent("onOtherSearchAction", "search_success");
	}

	goToRatesOneString() {
		let searchModel =
			this.props.searchOneStringModel || this.props.ApplicationModel.searcheModelOneString;
		let link = null;
		let searchHouse = false;

		let regions = this.props.ApplicationModel.siteConfig.regions.available;
		let currentRegion = this.props.ApplicationModel.currentRegion.url;
		let newRegionUrl = this.props.location?.state?.is404
			? currentRegion.url
			: this.props.match.params.regionUrl;

		let currentAddress = searchModel.getCurrentFullAddress();

		if (!currentAddress.data.address_id) {
			let addresses = searchModel.getFullAddresses();
			let found = addresses.find(
				(item) =>
					item.result.toLowerCase() ===
					searchModel.searcheForm.fields.fullAddress.value.toLowerCase()
			);
			if (!!found) {
				currentAddress = found;
				searchModel.setCurrentAddress(found);
			} else {
				currentAddress.data.address_id = -1;
			}
		}

		if (currentAddress.data.address_id === -1) {
			if (this.state.selectedRoute.route === this.dropdownSelectorVariants[0].route) {
				!!this.props.openPopup && this.props.openPopup();

				searchModel.emitSearchEvent("onOtherSearchAction", "search_fail");

				return;
			}

			let region = regions.find((item) => item.id === currentAddress.data.region_id);
			if (region && region.url !== newRegionUrl) {
				newRegionUrl = region.url;
				this.props.ApplicationModel.detectRegionModel.setAccept(region);
				this.props.ApplicationModel.setRegion(region);
			}

			link = generateUrl(this.state.selectedRoute.route, {
				regionUrl: newRegionUrl,
			});

			if (this.state.selectedRoute.route === this.dropdownSelectorVariants[1].route) {
				link = link + `?u_house=1`;
			}

			!!this.props.successCallback && this.props.successCallback();

			this.props.history.push(link);

			searchModel.emitSearchEvent("onOtherSearchAction", "search_fail");

			return;
		}

		if (this.state.selectedRoute.route === this.dropdownSelectorVariants[0].route) {
			searchHouse = true;
			let commonViewModel = this.props.ApplicationModel.commonViewModel;
			if (this.props.providerId) {
				let providerModel = this.props.ApplicationModel.providerModel;
				providerModel.setProviderIdForOrder(this.props.providerId);
			}
			commonViewModel.changeShowModalOnRates(true);
			commonViewModel.changeNameModalOnRates("quiz_search");
		}

		let region = regions.find((item) => item.id == currentAddress.data.region_id);
		if (region.url != newRegionUrl) {
			newRegionUrl = region.url;
			this.props.ApplicationModel.detectRegionModel.setAccept(region);
			this.props.ApplicationModel.setRegion(region);
		}

		// Уникальный URL для золотых домов
		link =
			searchHouse && Boolean(currentAddress.url)
				? generateUrl(Routes.RatesInHouse.route, {
						regionUrl: newRegionUrl,
						house_url: currentAddress.url,
						house_id: currentAddress.id,
					})
				: generateUrl(this.state.selectedRoute.route, {
						regionUrl: newRegionUrl,
					});
		if (
			this.state.selectedRoute.route === this.dropdownSelectorVariants[0].route ||
			this.state.selectedRoute.route === this.dropdownSelectorVariants[1].route
		) {
			searchModel.setHouseId(currentAddress.data.address_id);
		}
		this.props.history.push(link);
		searchModel.emitSearchEvent("onOtherSearchAction", "search_success");
	}

	onClickButton() {
		let searcheModel = this.props.searcheModel || this.props.ApplicationModel.searcheModel;
		let streetValue = searcheModel.searcheForm.fields.street.value;
		let addressValue = searcheModel.searcheForm.fields.address.value;
		this.removeError();
		if (!searcheModel.checkErrors()) {
			if (this.state.selectedRoute == -1 && streetValue && addressValue) {
				this.errorText = "Выберите тип подключения";
				this.setState({
					errorTypeConnection: true,
					error: true,
					errorCounter: this.state.errorCounter + 1,
				});
				return;
			}
			searcheModel.submit();
			this.props.cbBeforeAction && this.props.cbBeforeAction();
			if (this.props.fromTariff) {
				searcheModel.setViewQuizFromTariffs(this.props.fromTariff);
			}
			if (this.props.hideProviderLogo) {
				searcheModel.setViewQuizFromTariffs(false);
			}
			!!this.props.onClickButton ? this.props.onClickButton() : this.goToRates();
		} else {
			this.errorText = searcheModel.checkOnUnselectedStreet()
				? this.errorTextCommon
				: this.errorTextWithoutHouse;

			this.setState({
				error: searcheModel.checkOnUnselectedStreet() ? true : false,
				errorHouse: searcheModel.checkOnUnselectedStreet() ? false : true,
				errorCounter: this.state.errorCounter + 1,
			});
		}
		if (this.state.selectedRoute.id == 0) {
			sendMetriks("CLICK_FIND_TOHOME_BUTTON");
		} else {
			sendMetriks("CLICK_FIND_OFFICE_BUTTON");
		}
		if (this.state.selectedRoute.id == 1 && !!this.props.tender) {
			sendMetriks("CLICK_START_TENDER");
		} else if (this.state.selectedRoute.id == 1) {
			sendMetriks("CLICK_FIND_OFFICE_BUTTON");
		}

		if (
			this.props.isMainPage ||
			this.props.isOrderToHome ||
			this.props.tender ||
			this.props.is404
		) {
			Cookies.set("connectTariffFrom", "");
		}
	}

	onClickButtonOneString() {
		const searchModel =
			this.props.searchOneStringModel || this.props.ApplicationModel.searcheModelOneString;

		const fullAddressValue = searchModel.searcheForm.fields.fullAddress.value;

		this.removeError();
		searchModel.setSelectOneString(true);

		if (!searchModel.checkErrors()) {
			if (this.state.selectedRoute == -1 && fullAddressValue) {
				this.errorText = "Выберите тип подключения";
				this.setState({
					errorFullAddress: !!searchModel.checkOnUnselectedFullAddress(),
					errorTypeConnection: true,
					errorCounter: this.state.errorCounter + 1,
				});
				return;
			}

			searchModel.submit();
			this.props.cbBeforeAction && this.props.cbBeforeAction();

			if (this.props.fromTariff) {
				searchModel.setViewQuizFromTariffs(this.props.fromTariff);
			}

			if (this.props.hideProviderLogo) {
				searchModel.setViewQuizFromTariffs(false);
			}

			if (this.props.onClickButton) {
				this.props.onClickButton();
			} else {
				this.goToRatesOneString();
			}
		} else {
			this.errorText = searchModel.checkOnUnselectedFullAddress()
				? this.notSelectedFullAddress
				: this.errorTextWithoutHouse;

			this.setState({
				errorFullAddress: !!searchModel.checkOnUnselectedFullAddress(),
				errorTypeConnection: false,
				errorCounter: this.state.errorCounter + 1,
			});
		}

		if (this.state.selectedRoute.id == 0) {
			sendMetriks("CLICK_FIND_TOHOME_BUTTON_NEW_SEARCH");
		} else if (this.state.selectedRoute.id == 1 && this.props.tender) {
			sendMetriks("CLICK_START_TENDER_NEW_SEARCH");
		} else if (this.state.selectedRoute.id == 1) {
			sendMetriks("CLICK_FIND_OFFICE_BUTTON_NEW_SEARCH");
		} else if (this.state.selectedRoute.id == 2) {
			sendMetriks("CLICK_FIND_SAT_BUTTON_NEW_SEARCH");
		}

		if (
			this.props.isMainPage ||
			this.props.isOrderToHome ||
			this.props.tender ||
			this.props.is404
		) {
			Cookies.set("connectTariffFrom", "");
		}
	}

	clickInputHandler() {
		let searchModel;
		let one_string_search =
			this.props.ApplicationModel?.siteConfig?.settings?.one_string_search?.enabled;

		if (one_string_search) {
			searchModel =
				this.props.searchOneStringModel || this.props.ApplicationModel.searcheModelOneString;
		} else {
			searchModel = this.props.searcheModel || this.props.ApplicationModel.searcheModel;
		}

		if (one_string_search && !!searchModel.currentFullAddress.result) {
			this.setState({ hint: true });
		}

		let windowsModel = this.props.ApplicationModel.windowSizeModel.windowSizes;
		if (windowsModel.xs && this.buttonSearch) {
			this.buttonSearch.scrollIntoView({ block: "center" });
		}

		this.props.clickInputHandler && this.props.clickInputHandler();
	}

	onEnter() {
		if (!!this.props.onEnterHandler) {
			this.props.onEnterHandler();
			return;
		}
		this.onClickButton();
	}

	setRefHouseDropdown(input) {
		this.houseDropdownInput = input;
	}

	setRefButton(button) {
		this.buttonSearch = button;
	}

	render() {
		let searcheModel = this.props.searcheModel || this.props.ApplicationModel.searcheModel;
		let searchModel2 =
			this.props.searchOneStringModel || this.props.ApplicationModel.searcheModelOneString;
		let text = this.props.ApplicationModel.text;
		let currentAddress = searcheModel.getCurrentAddress();
		let currentStreet = searcheModel.getCurrentStreet();
		let streetValue = searcheModel.searcheForm.fields.street.value;
		let addressValue = searcheModel.searcheForm.fields.address.value;
		let windowSizes = this.props.ApplicationModel.windowSizeModel.windowSizes;
		let popupBView = this.props.ApplicationModel.modalModel.popapB;

		let one_string_search =
			this.props.ApplicationModel?.siteConfig?.settings?.one_string_search?.enabled;

		let currentFullAddress = searchModel2.getCurrentFullAddress();
		let fullAddressValue = searchModel2.searcheForm.fields.fullAddress.value;

		return (
			<AlertWrapper
				error={
					(this.state.error ||
						this.state.errorHouse ||
						this.state.errorFullAddress ||
						this.state.errorTypeConnection) &&
					this.errorText
				}
				showHint={!!one_string_search && !!this.state.showHint}
				hint={!!one_string_search && this.state.showHint && this.state.hint && this.hintText}
				providerColorHint={this.state.providerColorHint}
				otherColorHint={this.state.otherColorHint}
				center={true}
				absolute={!this.props.alertNotAbsolute}
				datatest={this.props.datatestForAlert}
			>
				{one_string_search ? (
					<SearchAddressOneString
						modalIsOpen={this.props.ApplicationModel.modalModel.isOpen}
						popupBView={popupBView}
						clickInputHandler={() => this.clickInputHandler()}
						text={text}
						showList={true}
						itemsFullAddresses={searchModel2.getFullAddresses()}
						error={this.props.error || this.state.errorFullAddress}
						errorTypeConnection={this.state.errorTypeConnection}
						errorCounter={this.state.errorCounter}
						onClickButton={() => this.onClickButtonOneString()}
						onInputHandler={(value) => this.onInputHandlerOneString(value)}
						onItemSelect={(item) => this.onFullAddressSelect(item)}
						fullAddressValue={fullAddressValue}
						tender={this.props.tender}
						modal={this.props.modal}
						smallButton={this.props.smallButton}
						withoutContainer={this.props.withoutContainer}
						withoutButton={this.props.withoutButton}
						datatest={this.props.datatest}
						datatestButton={this.props.datatestButton}
						currentFullAddress={currentFullAddress}
						withBorder={this.props.withBorder}
						buttonName={this.props.buttonName}
						shortButtonName={this.props.shortButtonName}
						withoutShadow={this.props.withoutShadow}
						withoutSwitcher={this.props.withoutSwitcher}
						layoutB={this.props.layoutB}
						onDropdownOpenCallback={() => this.onDropdownOpenCallback()}
						onDropdownCloseCallback={() => this.onDropdownCloseCallbackOneString()}
						direction={this.props.direction}
						onEnter={() => this.onEnter()}
						withoutBorder={this.props.withoutBorder}
						whiteTheme={this.props.whiteTheme}
						isFixedList={this.props.isFixedList}
						loading={searchModel2.loading}
						listContainerFunc={this.props.listContainerFunc}
						windowSizes={windowSizes}
						dropdownSelectorVariants={this.dropdownSelectorVariants}
						setRef={(input) => this.setRefHouseDropdown(input)}
						changeSelectRoute={(item) => this.changeSelectRoute(item)}
						focusOnDropdownHouse={() => this.focusOnDropdownHouse()}
						notJustifyCenter={this.props.notJustifyCenter}
						defaultVariantDropdown={
							!!this.props.defaultVariantDropdown ? this.props.defaultVariantDropdown : 0
						}
						onMountSelFil={this.onMountSelFil}
						refButton={(button) => this.setRefButton(button)}
					/>
				) : (
					<SearchAddressB
						redirectToSelectRegion={this.props.redirectToSelectRegion}
						streetInputId={this.props.streetInputId}
						houseInputId={this.props.houseInputId}
						errorTypeConnection={this.state.errorTypeConnection}
						modalIsOpen={this.props.ApplicationModel.modalModel.isOpen}
						popupBView={popupBView}
						clickInputHandler={() => this.clickInputHandler()}
						text={text}
						showList={true}
						itemsStreets={searcheModel.getStreets()}
						itemsAddresses={searcheModel.getAddresses()}
						error={this.props.error || this.state.error}
						errorStreet={this.props.error || this.state.errorStreet}
						errorHouse={this.props.error || this.state.errorHouse}
						errorCounter={this.state.errorCounter}
						onClickButton={() => this.onClickButton()}
						onInputHandler={(field, value) => this.onInputHandler(field, value)}
						onItemSelect={(field, item) => this.onItemSelect(field, item)}
						streetValue={streetValue}
						addressValue={addressValue}
						tender={this.props.tender}
						layoutB={this.props.layoutB}
						layoutBV2={this.props.layoutBV2}
						renderFormV2={this.props.renderFormV2}
						apartment={this.props.apartment}
						autoExpansion={this.props.autoExpansion}
						modal={this.props.modal}
						smallButton={this.props.smallButton}
						withoutContainer={this.props.withoutContainer}
						withoutButton={this.props.withoutButton}
						datatest={this.props.datatest}
						datatestButton={this.props.datatestButton}
						currentAddress={currentAddress}
						currentStreet={currentStreet}
						withBorder={this.props.withBorder}
						buttonName={this.props.buttonName}
						shortButtonName={this.props.shortButtonName}
						withoutShadow={this.props.withoutShadow}
						withoutSwitcher={this.props.withoutSwitcher}
						onDropdownOpenCallback={(type) => this.onDropdownOpenCallback(type)}
						onDropdownCloseCallback={() => this.onDropdownCloseCallback()}
						direction={this.props.direction}
						onEnter={() => this.onEnter()}
						withoutBorder={this.props.withoutBorder}
						whiteTheme={this.props.whiteTheme}
						isFixedList={this.props.isFixedList}
						loading={searcheModel.loading}
						listContainerFunc={this.props.listContainerFunc}
						windowSizes={windowSizes}
						dropdownSelectorVariants={this.dropdownSelectorVariants}
						setRef={(input) => this.setRefHouseDropdown(input)}
						changeSelectRoute={(item) => this.changeSelectRoute(item)}
						focusOnDropdownHouse={() => this.focusOnDropdownHouse()}
						notJustifyCenter={this.props.notJustifyCenter}
						defaultVariantDropdown={
							!!this.props.defaultVariantDropdown ? this.props.defaultVariantDropdown : 0
						}
						onMountSelFil={this.onMountSelFil}
						refButton={(button) => this.setRefButton(button)}
						selectPlaceholder={text.TYPE_CONNECT}
					/>
				)}
			</AlertWrapper>
		);
	}
}
